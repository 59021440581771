export function toBase64UrlSafe(str) {
  // Step 1: Encode to Base64
  let base64 = btoa(str);

  // Step 2: Replace `+` with `-`, `/` with `_`, and remove `=`
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

export function fromBase64UrlSafe(base64Url) {
  // Step 1: Replace URL-safe characters back to original Base64 characters
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

  // Step 2: Pad with `=` if needed to make the length a multiple of 4
  while (base64.length % 4 !== 0) {
    base64 += "=";
  }

  // Step 3: Decode from Base64
  return atob(base64);
}
