import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Icons from './components/Icons';
import Routing from './components/Routing';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <Routing/>
      <Icons/>
    </React.StrictMode>
);