import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";

function AddHeadOfficeTransaction({
  showAddCred,
  handleCloseAddCred,
  handleAddEditMobileCreds,
  isUpdatingCreds,
  label,
  paymentType,
}) {
  // "trans_date":"2024-10-10",
  //   "payment_type":"1",
  //   "from_code":"E001",
  //   "to_code":"V001",
  //   "amount":"1000"

  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [fromCode, setFromCode] = useState();
  const [toCode, setToCode] = useState();
  const [recBy, setRecBy] = useState();
  const [amount, setAmount] = useState("");
  const [userList, setUserList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const handleClose = () => {
    setDate("");
    setFromCode("");
    setToCode("");
    setAmount("");
    setRecBy("");
    handleCloseAddCred();
  };

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getcontractorsbyledgermaster`,
        method: "GET",
        params: {
          ledger_master: type || "",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        // setUserList(
        return response.data?.data || [];
        // [
        //   // {
        //   //   code: "E001",
        //   //   name: "E001",
        //   //   id: 1,
        //   // },
        //   // {
        //   //   code: "V001",
        //   //   name: "V001",
        //   //   id: 2,
        //   // },
        // ]
        // );
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    if (showAddCred?.id) {
      setDate(showAddCred?.him_mobile_user?.username);
    }
  }, [showAddCred]);

  useEffect(() => {
    (async () => {
      setUserList(
        await getContractors("Firm,Vendor,Employee,Bank,Head Office")
      );
      setEmployeeList(await getContractors("Employee"));
    })();
  }, []);

  return (
    <Modal show={showAddCred} onHide={handleClose} animation={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddEditMobileCreds(
              e,
              date,
              paymentType,
              fromCode?.value || null,
              toCode?.value || null,
              amount,
              handleClose,
              recBy?.value || null
            )
          }
        >
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Date
            </label>
            <input
              required
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              className="input-box"
              placeholder="Select Date"
              onFocus={(e) => e.target.showPicker()}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              From Code
            </label>
            {/* select dropdown */}
            {/* <select
              value={fromCode}
              onChange={(e) => setFromCode(e.target.value)}
              className="input-box"
              id="cars"
              name="cars"
            >
              <option value="">Select From Code</option>
              {userList.map((user) => (
                <option key={user?.id} value={user?.code}>
                  {user?.code + "-" + user?.name}
                </option>
              ))}
            </select> */}
            <Select
              // className="input-box"
              value={fromCode}
              onChange={setFromCode}
              options={userList.map((user) => ({
                value: user?.code,
                label: user?.code + "-" + user?.name,
              }))}
              placeholder="Select From Code"
              isSearchable
              isClearable
            />
          </div>
          {paymentType == 1 && (
            <div className="mb-2">
              <label htmlFor="" className="mb-10 d-block gray">
                To Code
              </label>
              {/* select dropdown */}
              {/* <select
                value={toCode}
                onChange={(e) => setToCode(e.target.value)}
                className="input-box"
                id="cars"
                name="cars"
              >
                <option value="">Select To Code</option>
                {userList.map((user) => (
                  <option key={user?.id} value={user?.code}>
                    {user?.code + "-" + user?.name}
                  </option>
                ))}
              </select> */}
              <Select
                // className="input-box"
                value={toCode}
                onChange={setToCode}
                options={userList.map((user) => ({
                  value: user?.code,
                  label: user?.code + "-" + user?.name,
                }))}
                placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div>
          )}
          {paymentType == 2 && (
            <div className="mb-2">
              <label htmlFor="" className="mb-10 d-block gray">
                Cash Received By
              </label>
              {/* select dropdown */}

              <Select
                // className="input-box"
                value={recBy}
                onChange={setRecBy}
                options={employeeList.map((user) => ({
                  value: user?.code,
                  label: user?.code + "-" + user?.name,
                }))}
                placeholder="Select Cash Received By"
                isSearchable
                isClearable
              />
            </div>
          )}
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Amount
            </label>
            <input
              required
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Amount"
            />
          </div>

          <div className="mb-2 mt-4">
            <button
              disabled={isUpdatingCreds}
              type="submit"
              className="button button-brown w-100"
            >
              {isUpdatingCreds ? <Loader /> : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddHeadOfficeTransaction;
