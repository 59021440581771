import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import Loader from "../components/Loader";
function Login() {
  const [loginDetails, setloginDetails] = useState({
    email: "",
    password: "",
    eyeShown: false,
  });
  const [error, seterror] = useState({
    status: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const emailInputRef = useRef();
  const passInputRef = useRef();

  async function handleLogin(e) {
    seterror({
      status: false,
      message: "",
    });
    e.preventDefault();
    setIsLoading(true);
    const email = emailInputRef.current.value;
    const pass = passInputRef.current.value;
    try {
      const cipherText = CryptoJS.AES.encrypt(
        pass,
        process.env.REACT_APP_SECRET
      );
      const data = {
        email: email,
        password: cipherText.toString(),
        // app_type: "admin",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/himuser/login`,
        data
      );
      if (response.status == 200) {
        setIsLoading(false);
        if (response.data.success) {
          localStorage.setItem("him_user_token", response?.data?.token);
          localStorage.setItem(
            "him_user_data",
            JSON.stringify(response?.data?.data)
          );
          navigate("/", { replace: true });
        }
      } else {
        setIsLoading(false);
        alert("Error");
        console.log("Error");
      }
    } catch (error) {
      seterror({
        status: true,
        message: error?.response?.data?.msg,
      });
      setIsLoading(false);
      console.log(error);
    }
  }
  return (
    <>
      <div className="logi-section">
        <img
          className="login-shape1"
          src={window.location.origin + "/images/shape1.png"}
          alt="shape1"
        />
        <img
          className="login-shape2"
          src={window.location.origin + "/images/shape2.png"}
          alt="shape1"
        />
        <img
          className="login-shape3"
          src={window.location.origin + "/images/shape3.png"}
          alt="shape1"
        />
        <form onSubmit={handleLogin}>
          <div className="logo-main">
            <div className="logo-box">
              <img
                className="logo"
                src={window.location.origin + "/images/logo.png"}
                alt="logo"
              />
            </div>
          </div>
          <label className="fs-18 text-brown fw-600 mb-15 d-block">
            Username
          </label>
          <div className="input-wrapper mb-40">
            <input
              ref={emailInputRef}
              required
              className="text-input-color"
              type="email"
              placeholder="Type your username"
              name="email"
            />
            <img
              className="input-icon"
              src={window.location.origin + "/images/user.png"}
              alt="icon"
            />
          </div>
          <label className="fs-18 text-brown fw-600 mb-15 d-block">
            Password
          </label>
          <div className="input-wrapper">
            <input
              ref={passInputRef}
              required
              className="text-input-color"
              type="password"
              placeholder="Type your password"
              name="password"
            />
            <img
              className="input-icon"
              src={window.location.origin + "/images/password.png"}
              alt="icon"
            />
          </div>
          <div className=" mt-40">
            {error?.status && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                }}
              >
                {error?.message}
              </p>
            )}
          </div>
          <div className="input-wrapper mt-10">
            <button disabled={isLoading} type="submit" className="button">
              {isLoading ? <Loader /> : "Login"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
