import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import moment from "moment";
import Select from "react-select";
import InvoiceViewModal from "../components/InvoiceViewModal";

function PurchaseConslusion() {
  let columns = [
    {
      name: "Date",
      code: "date",
      format: (value) => moment(value).format("DD MMM YYYY"),
    },

    {
      name: "Vendor Name",
      code: "vendor",
      format: (value) => (value != null ? value["name"] : ""),
    },
    {
      name: "Vendor Code",
      code: "vendor",
      format: (value) => (value != null ? value["code"] : ""),
    },
    {
      name: "Invoice No",
      code: "purchase",
      format: (value) => {
        if (value != null && value["inv_no"]) {
          if (value["inv_file"]) {
            return (
              <span>
                {value["inv_no"]}

                <button
                  onClick={() =>
                    handleshowInvoice({
                      inv_file: value["inv_file"],
                      inv_no: value["inv_no"],
                    })
                  }
                  type="button"
                  className="button "
                >
                  <svg className="icon fs-22">
                    <use href="#icon_eye"></use>
                  </svg>
                </button>
              </span>
            );
          } else {
            return value["inv_no"];
          }
        } else {
          return "";
        }
      },
    },
    {
      name: "Purchase Item",
      code: "site_item",
      format: (value) => (value != null ? value["item_name"] : ""),
    },
    {
      name: "Purchase Qty",
      code: "purchase_qty",
    },
    {
      name: "Verified Qty",
      code: "verify_qty",
    },
    {
      name: "Discrepancy Qty",
      code: "discrepancy_qty",
    },
    {
      name: "Return Qty",
      code: "return_qty",
    },
    {
      name: "Adjust Qty",
      code: "adjust_qty",
    },
    {
      name: "Creator Code",
      code: "user_code",
    },
    {
      name: "Verifier Code",
      code: "verify_by_user_code",
    },
  ];
  const [rows, setRows] = useState([]);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [showInvoice, setShowInvoice] = useState(false);
  function handleshowInvoice(data) {
    setShowInvoice(data);
  }
  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  const [selectedUser, setSelectedUser] = useState();
  const [selectedSite, setSelectedSite] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [userList, setUserList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  async function getRows() {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/purchasediscrepancy`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          user_code: selectedUser?.value || null,
          site_code: selectedSite?.value || null,
          date: selectedDate,
          action_type: 2,
        },
      });

      setRows(response?.data?.data || []);
      // setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getcontractorsbyledgermaster`,
        params: {
          ledger_master: type,
        },
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data || [];
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      return [];
    }
  }

  // async function handleSaveReturn() {
  //   setIsSaving(true);
  //   try {
  //     const token = localStorage.getItem("him_user_token");
  //     const response = await axios({
  //       url: `${process.env.REACT_APP_BASE_URL}/himreport/purchasereturn`,
  //       method: "POST",
  //       headers: {
  //         jwt_token: token,
  //       },
  //       data: {
  //         site_code: selectedSite?.value || null,
  //         date: selectedDate,
  //         data: rows?.map((row) => {
  //           return {
  //             site_code: row?.site_code,
  //             purchase_id: row?.purchase_id,
  //             date: row?.date,
  //             id: row?.id,
  //             item_id: row?.item_id,
  //             tax_per_unit: row?.site_item?.gst_percent,
  //             amount_per_unit: row?.amount_per_unit,
  //             adjust_qty: row?.adjust_qty,
  //             return_qty: row?.return_qty,
  //           };
  //         }),
  //       },
  //     });

  //     if (response?.status === 200) {
  //       setshowSuccess(true);
  //       setSuccessMessagePopup(
  //         response?.data?.msg || "Data saved successfully"
  //       );
  //     } else {
  //       setShowErrModal(true);
  //       setError({ title: "Error", err: response?.data?.message });
  //     }
  //   } catch (error) {
  //     setShowErrModal(true);
  //     setError({ title: "Error", err: error?.message });
  //     if (error?.response?.status == 403) {
  //       localStorage.removeItem("him_user_token");
  //       localStorage.removeItem("him_user_data");
  //       navigate("/login");
  //       return;
  //     }

  //     if (error?.code === "ECONNABORTED") {
  //       console.log("Request timed out");
  //     } else {
  //       console.log("An error occurred:", error);
  //     }
  //   } finally {
  //     setIsSaving(false);
  //   }
  // }

  const exportToExcel = () => {
    const tableData = rows.map((item, index) => {
      const data = {
        "Sr.No.": index + 1,
      };
      columns.forEach((column) => {
        data[column.name] = column.format
          ? column.format(item[column.code])
          : item[column.code];
      });
      return data;
    });
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Export to Excel file
    XLSX.writeFile(workbook, `${"purchase_conclusion"}.xlsx`);
  };

  useEffect(() => {
    getRows();
  }, [selectedUser, selectedSite, selectedDate]);
  useEffect(() => {
    async function fetchData() {
      const users = await getContractors("Employee");
      const sites = await getContractors("Office");
      setUserList(users);
      setSiteList(sites);
    }
    fetchData();
  }, []);
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Purchase Conclusion</h1>
          {/* <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={handleShow}
            >
              Upload
            </button>
          </div> */}
        </div>

        {/* new add */}
        <div className="mb-20 d-flex gray justify-content-between">
          <div className="d-flex">
            <div className="w-45 me-4">
              <label htmlFor="" className="mb-10 d-block">
                Choose User
              </label>

              <Select
                // className="input-box"
                value={selectedUser}
                onChange={setSelectedUser}
                options={userList.map((user) => ({
                  value: user?.code,
                  label: user?.code + "-" + user?.name,
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div>
            <div className="w-45 me-4">
              <label htmlFor="" className="mb-10 d-block">
                Choose Site
              </label>

              <Select
                // className="input-box"
                value={selectedSite}
                onChange={setSelectedSite}
                options={siteList.map((user) => ({
                  value: user?.code,
                  label: user?.code + "-" + user?.name,
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div>
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                Date
              </label>
              <input
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div>
            {/* <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                To Date
              </label>
              <input
                value={selectedToDate}
                onChange={(e) => setSelectedToDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div> */}
          </div>
          <div
            style={{
              alignSelf: "flex-end",
            }}
          >
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={exportToExcel}
              disabled={isGettingRows}
            >
              Export
            </button>
          </div>
        </div>
        {/* <hr className="mr-30 my-10"></hr>
        <div className="d-flex jc-between my-10">
          <div
            style={{
              visibility: "hidden",
            }}
          >
            <button type="button" className="button button-brown mr-30">
              View grid
            </button>
            <button type="button" className="button button-brown mr-30">
              Save
            </button>
            <button className="btn-undo" type="button">
              <svg className="icon fs-30 pt-10">
                <use href="#icon_undo"></use>
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button button-brown mr-30"
              // onClick={handleShowAddCred}
            >
              Export
            </button>
          </div>
        </div> */}
        <div className="tbl-scroller">
          {!isGettingRows && rows?.length ? (
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="srtd">Sr.No.</th>
                  {columns.map((column) => (
                    <th key={column.code}>{column.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="tbody-gap">
                <tr>
                  <td colSpan="13"></td>
                </tr>
              </tbody>

              <tbody>
                {rows.map((row, index) => (
                  <>
                    <tr key={row?.id}>
                      <td>{index + 1}</td>
                      {columns.map((column) => (
                        <td key={column.code}>
                          {column.format
                            ? column.format(row[column.code])
                            : row[column.code]}
                        </td>
                      ))}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {/* {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )} */}
        </div>

        {/* <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
          </div>
        </form> */}
      </div>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <InvoiceViewModal
        showInvoice={showInvoice}
        handleClose={() => setShowInvoice(false)}
      />
    </>
  );
}

export default PurchaseConslusion;
