import React from "react";

function ThankYou() {
  return (
    <>
      <div className="thankBox text-center">
        <div className="content">
          <div className="d-flex justify-content-center">
            <div className="ThankImg">
              <img
                src={`${window.origin}/images/thank_you.png`}
                alt="thank you"
              />
            </div>
          </div>
          <h5 className="display-6 fw-bold pt-md-5 pt-4">
            Your Information Has Been Submitted!
          </h5>
        </div>
      </div>
    </>
  );
}

export default ThankYou;
