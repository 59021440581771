import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "./Loader";

function UploadFile({
  show,
  setShow,
  selectedFile,
  setSelectedFile,
  onUpload,
  isUploading,
  modalTitle,
}) {
  const imgUpload = (e, id) => {
    var checker = e.target?.files[0]?.name?.split(".");
    // if (checker[1] === "pdf" || checker[1] === "csv") {
    // document.getElementById(id).innerHTML = e.target.files[0].name;
    // } else {
    //   e.target.value = "";
    //   alert("this format not allow only pdf and csv allow");
    //   return false;
    // }
    if (checker) {
      setSelectedFile && setSelectedFile(e.target.files[0]);
    }
  };

  const handleClose = () => setShow(false);
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="upload-box p-20">
            <h3>{modalTitle}</h3>
            <h6 className="pt-10">Only EXCEL file accepted</h6>
            <label className="drag-box">
              <svg className="icon">
                <use href="#icon_upload"></use>
              </svg>
              <input
                onChange={(e) => imgUpload(e, "upload2")}
                accept=".xls,.xlsx"
                type="file"
              ></input>
              <span>Drag and drop your file here or click to upload</span>
            </label>
            <div className="text-center mt-1" id="upload2">
              {selectedFile ? selectedFile.name : "No file selected"}
            </div>
            <button
              onClick={onUpload ? () => onUpload(selectedFile) : null}
              disabled={!selectedFile || isUploading}
              type="button"
              className="button button-brown mt-20 w-100"
            >
              {isUploading ? <Loader /> : "Upload"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UploadFile;
