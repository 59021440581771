import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import Loader from "./Loader";

function AddArticleGstModal({
  showAddItem,
  handleCloseAddItem,
  handleAddEditItems,
  isUpdatingItem,
}) {
  const [article, setArticle] = useState("");
  const [gst_ratio, setGst_ratio] = useState("");

  const handleClose = () => {
    setArticle("");
    setGst_ratio("");
    handleCloseAddItem();
  };

  useEffect(() => {
    if (showAddItem?.item_id) {
      setArticle(showAddItem?.item_name);
      setGst_ratio(showAddItem?.gst_percent);
    }
  }, [showAddItem]);

  return (
    <Modal show={showAddItem} onHide={handleClose} animation={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddEditItems(e, article, gst_ratio, handleClose)
          }
        >
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Item
            </label>
            <input
              required
              value={article}
              onChange={(e) => setArticle(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Item"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              GST Ratio
            </label>
            <input
              required
              value={gst_ratio}
              onChange={(e) => setGst_ratio(e.target.value)}
              type="number"
              className="input-box"
              placeholder="Enter GST Ratio"
            />
          </div>

          <div className="mb-2">
            <button
              disabled={isUpdatingItem}
              type="submit"
              className="button button-brown w-100"
            >
              {isUpdatingItem ? <Loader /> : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddArticleGstModal;
