import axios from "axios";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../components/Loader";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
function VendorQuotes() {
  const navigate = useNavigate();
  //   const [show, setShow] = useState(false);
  //   aside
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [rows, setRows] = useState([]);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedSite, setSelectedSite] = useState();
  const [selectedVendor, setSelectedVendor] = useState();
  const [quoteType, setQuoteType] = useState("measurements");
  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }
  //   search select

  const [optionsFirm, setOptionsFirm] = useState([]);
  const [optionsVendor, setOptionsVendor] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");

  async function getRows() {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getvendorquote`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          from_date: selectedFromDate
            ? selectedFromDate?.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate ? selectedToDate?.format("YYYY-MM-DD") : null,
          site_code: selectedSite?.value,
          vendor_code: selectedVendor?.value,
          quote_type: quoteType,
        },
      });

      setRows(response?.data?.data);

      // setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getcontractorsbyledgermaster`,
        method: "GET",
        params: {
          ledger_master: type,
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data;
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    getRows();
  }, [
    selectedFromDate,
    selectedToDate,
    selectedSite,
    selectedVendor,
    quoteType,
  ]);

  // useEffect(() => {
  //   if (!searchInput) {
  //     getRows();
  //   }
  // }, [searchInput]);

  useEffect(() => {
    (async () => {
      // setSiteList((await getContractors("Office")) || []);
      setOptionsFirm(await getContractors("Firm"));
      setOptionsVendor(await getContractors("Vendor"));
    })();
  }, []);

  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Vendor Quotes</h1>
        </div>
        <div className="d-flex mb-4">
          {/* <div className="maxw-300 d-flex ">
            <input
              type="search"
              className="input-box "
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              disabled={!searchInput.length}
              onClick={() => {
                getRows();
              }}
              className="button button-brown ms-2"
            >
              <span>
                <svg className="icon">
                  <use href="#icon_search"></use>
                </svg>
              </span>
            </button>
          </div> */}
          {/* <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={() => setDisplayModal(true)}
            >
              Download sample
            </button>
            <button className="button button-brown px-4 ms-5" type="button">
              Bulk upload
            </button>
            <button
              className="button button-brown px-4 ms-5"
              type="button"
              onClick={handleShow}
            >
              + Add Bills
            </button>
          </div> */}
        </div>
        <div className="d-flex">
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose Firm
            </label>

            <Select
              value={selectedSite}
              onChange={setSelectedSite}
              options={optionsFirm.map((user) => ({
                value: user?.code,
                label: user?.code + "-" + user?.name,
              }))}
              isSearchable
              isClearable
            />
          </div>
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose Vendor
            </label>

            <Select
              value={selectedVendor}
              onChange={setSelectedVendor}
              options={optionsVendor.map((user) => ({
                value: user?.code,
                label: user?.code + "-" + user?.name,
              }))}
              isSearchable
              isClearable
            />
          </div>
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose Quote Type
            </label>

            <select
              className="input-box  mx-auto"
              aria-label="rateSelect"
              onChange={(e) => setQuoteType(e.target.value)}
              value={quoteType}
            >
              <option selected value="measurements">
                Rates In Measurements
              </option>
              <option value="quantity">Rates In Quantity</option>
            </select>
          </div>

          {/* <div className="w-45 mr-30">
            <label htmlFor="" className="mb-10 d-block">
              Date
            </label>
            <RangePicker
              className="input-box gray"
              value={[selectedFromDate, selectedToDate]}
              format={"DD MMM YYYY"}
              onChange={(dates) => {
                setSelectedFromDate(dates?.length ? dates[0] : "");
                setSelectedToDate(dates?.length ? dates[1] : "");
              }}
            />
          </div> */}
        </div>

        <div className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="srtd">Sr. No.</th>
                <th>Name Of Firm</th>
                <th>Qutote By</th>
                <th>Item No.</th>
                <th>Item Details</th>
                {quoteType == "measurements" && (
                  <>
                    <th>Nos</th>
                    <th>Length</th>
                    <th>Breadth</th>
                    <th>Height</th>
                  </>
                )}
                <th>Quantity</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    <td>{index + 1}</td>
                    <td>
                      {row?.company?.code} - {row?.company?.name}
                    </td>
                    <td>
                      {row?.vendor?.code} - {row?.vendor?.name}
                    </td>
                    <td>{row?.item_no}</td>
                    <td>{row?.item_name}</td>
                    {quoteType == "measurements" && (
                      <>
                        <td>{row?.nos}</td>
                        <td>{row?.length}</td>
                        <td>{row?.breadth}</td>
                        <td>{row?.height}</td>
                      </>
                    )}
                    <td>{row?.qty}</td>
                    <td>{row?.unit}</td>
                    <td>{row?.rate}</td>
                    <td>{row?.amount}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>
      </div>

      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    </>
  );
}

export default VendorQuotes;
