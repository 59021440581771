import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AsideBar from "./AsideBar";
import NavBar from "./NavBar";
import axios from "axios";
import { ConfigProvider } from "antd";

function LayoutOutlet() {
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);

  async function getAllReports() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/getreportslist`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        let reports = JSON.stringify(response.data?.data);
        setReports(response.data?.data);
        localStorage.setItem("him_reports", reports);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  useEffect(() => {
    if (
      !localStorage.getItem("him_user_token") ||
      !localStorage.getItem("him_user_data")
    ) {
      navigate("/login");
      return;
    }
    getAllReports();
  }, []);

  // useEffect(() => {}, [reports]);
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#c8a366",
          },
        }}
      >
        <AsideBar reports={reports || []} />
        <div className="wrapper">
          {/* <NavBar/> */}
          <Outlet context={{ reports: reports || [] }}></Outlet>
        </div>
      </ConfigProvider>
    </>
  );
}

export default LayoutOutlet;
