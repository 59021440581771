import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import UploadFile from "../components/UploadFile";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddMobileCredentials from "../components/AddMobileCredentials";
import Loader from "../components/Loader";
import Select from "react-select";
import CryptoJS from "crypto-js";
import { toBase64UrlSafe } from "../utils/comman";

function AllCodeSheet() {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [isUpdatingCreds, setIsUpdatingCreds] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedFile(null);
  };
  const handleShow = () => setShow(true);

  //
  const [showAddCred, setShowAddCred] = useState(false);

  const handleCloseAddCred = () => setShowAddCred(false);
  const handleShowAddCred = (data) => setShowAddCred(data);

  const navigate = useNavigate();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  async function handleFileUpload(event) {
    // event && event?.preventDefault();
    if (!selectedFile) {
      setError({ title: "Error", err: "Please select a file to upload" });
      setShowErrModal(true);
      return;
    }
    setIsUploading(true);
    try {
      const token = localStorage.getItem("him_user_token");
      const formData = new FormData();
      // rawFile?.forEach((file) => {
      formData.append("document", selectedFile);
      // });

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/uploadcodesheet`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: formData,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        handleClose();
        setTimeout(() => {
          !response?.data?.errorItems?.length && handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsUploading(false);
      setPage("0");
    }
  }

  async function getRows(category) {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/getcodesheet`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
        params: {
          page: parseInt(page),
          limit: limit,
          category: category,
        },
      });

      setRows(response?.data?.data);
      setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function handleAddEditMobileCreds(
    event,
    username,
    password,
    selectedReports,
    role_id,
    associated_codes,
    handleCloseCallback
  ) {
    event && event?.preventDefault();
    if (
      showAddCred?.him_ledger_master?.name != "Head Office" &&
      !selectedReports?.length
    ) {
      setError({ title: "Error", err: "Please select atleast 1 report." });
      setShowErrModal(true);
      return;
    }
    setIsUpdatingCreds(true);
    try {
      const token = localStorage.getItem("him_user_token");

      const data = {
        username,
        password,
        allowed_reports: selectedReports,
        contractor_id: showAddCred?.id,
        role_id: role_id,
        headoffice: showAddCred?.him_ledger_master?.name == "Head Office",
        associated_codes: associated_codes,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/addeditmobileuser`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
        handleCloseCallback ? handleCloseCallback() : handleCloseAddCred();
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsUpdatingCreds(false);
      setPage("0");
    }
  }

  async function getAllCategory() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getcategory`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setCategoryList(response.data?.data);
      } else {
        setCategoryList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  useEffect(() => {
    getRows(selectedCategory?.value || null);
  }, [page, limit]);

  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">All Code Sheet</h1>
          <div className="ms-auto">
            <button
              className="button button-brown px-4 me-4"
              type="button"
              disabled={isDownloading}
              onClick={() => {
                setIsDownloading(true);
                const link = document.createElement("a");
                link.href = "/sample.xlsx";
                link.download = "all_code_sheet.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setIsDownloading(false);
              }}
            >
              {isDownloading ? <Loader /> : "Download Sample"}
            </button>
            <button
              className="button button-brown px-4"
              type="button"
              onClick={handleShow}
            >
              Upload
            </button>
          </div>
        </div>

        {/* new add */}
        <div className="mb-20 d-flex gray">
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose category
            </label>
            <Select
              // className="input-box"
              value={selectedCategory}
              onChange={(option) => {
                setSelectedCategory(option);
                if (page == 0) {
                  getRows(option?.value || null);
                } else {
                  setPage(0);
                }
              }}
              options={categoryList.map((user) => ({
                value: user?.id,
                label: user?.name,
              }))}
              // placeholder="Select To Code"
              isSearchable
              isClearable
            />
          </div>
          {/* <div className="w-45 mr-30">
            <label htmlFor="" className="mb-10 d-block">
              Choose title
            </label>
            <select className="input-box" id="cars" name="cars">
              <option value="title">Choose title</option>
              <option value="title">Choose title #1</option>
              <option value="title">Choose title #2</option>
              <option value="title">Choose title #3</option>
              <option value="title">Choose title #4</option>
            </select>
          </div> */}
        </div>
        {/* <hr className="mr-30 my-10"></hr>
        <div className="d-flex jc-between my-10">
          <div>
            <button type="button" className="button button-brown mr-30">
              View grid
            </button>
            <button type="button" className="button button-brown mr-30">
              Save
            </button>
            <button className="btn-undo" type="button">
              <svg className="icon fs-30 pt-10">
                <use href="#icon_undo"></use>
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={handleShowAddCred}
            >
              Export
            </button>
          </div>
        </div> */}
        <div className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="srtd"> Sr. No. </th>
                <th> Code </th>
                <th> Ledger Head </th>
                <th> Category </th>
                <th> Name </th>
                <th> Associated Codes </th>
                {/* <th> Company Code </th>
                <th> Vehicle No. </th>
                <th> Employee Profile </th>
                <th> Contact Details </th>
                <th> Vendor Contact Person </th>
                <th> Contact Details </th>
                <th> Account Number </th>
                <th> IFSC </th> */}
                <th> Mobile Login </th>
                <th>Emp. Username</th>
                <th>Emp. Password</th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    <td>{parseInt(page) * limit + index + 1}</td>
                    <td>
                      {row?.code}
                      {row?.him_ledger_master?.name == "Vendor" && row?.code ? (
                        <button
                          onClick={() => {
                            //create new link and copy to clipboard and show success message
                            const el = document.createElement("textarea");
                            // el.value = URL.parse(
                            //   `${window.origin}/vendor/${CryptoJS.AES.encrypt(
                            //     row?.code,
                            //     process.env.REACT_APP_SECRET
                            //   ).toString()}`
                            // );
                            el.value = URL.parse(
                              `${window.origin}${
                                process.env.REACT_APP_ENV == "dev"
                                  ? "/testhim"
                                  : ""
                              }/vendor/${toBase64UrlSafe(row?.code)}`
                            );
                            document.body.appendChild(el);
                            el.select();
                            document.execCommand("copy");
                            document.body.removeChild(el);
                            setshowSuccess(true);
                            setSuccessMessagePopup(
                              "Vendor URL copied to clipboard"
                            );
                            setTimeout(() => {
                              handleCloseSuccess();
                            }, 2000);
                          }}
                          type="button"
                          className="button "
                        >
                          <svg className="icon fs-22">
                            <use href="#icon_copy"></use>
                          </svg>
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{row?.him_ledger_head?.name}</td>
                    <td>{row?.him_ledger_master?.name}</td>
                    <td>{row?.name}</td>
                    <td>{row?.associated_codes}</td>
                    {/* <td>{row?.company_code}</td>
                    <td>{row?.vehicle_no}</td>
                    <td>{row?.emp_profile}</td>
                    <td>{row?.contact_person}</td>
                    <td>{row?.vendor_person}</td>
                    <td>{row?.vendor_contact}</td>
                    <td>{row?.account}</td>
                    <td>{row?.ifsc_code}</td> */}
                    {row?.him_ledger_master?.name == "Employee" ||
                    row?.him_ledger_master?.name == "Head Office" ? (
                      <>
                        <td>
                          <button
                            onClick={() => handleShowAddCred(row)}
                            className="button  px-2"
                            type="button"
                          >
                            <svg className="icon fs-22">
                              <use href="#icon_edit"></use>
                            </svg>
                          </button>
                        </td>
                        <td>{row?.him_mobile_user?.username}</td>
                        <td>{row?.him_mobile_user?.password}</td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>

        <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
          </div>
        </form>
      </div>

      <UploadFile
        modalTitle="Upload Code Sheet"
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        show={show}
        setShow={setShow}
        onUpload={handleFileUpload}
        isUploading={isUploading}
      />
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <AddMobileCredentials
        showAddCred={showAddCred}
        handleCloseAddCred={handleCloseAddCred}
        handleAddEditMobileCreds={handleAddEditMobileCreds}
        isUpdatingCreds={isUpdatingCreds}
      />
    </>
  );
}

export default AllCodeSheet;
