import axios from "axios";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../components/Loader";

function AllBill() {
  const navigate = useNavigate();
  //   const [show, setShow] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  //   aside
  const [show, setShow] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [rows, setRows] = useState([]);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedSite, setSelectedSite] = useState();
  const [siteList, setSiteList] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [serviceList, setServiceList] = useState([]);
  const [isBillAdding, setIsBillAdding] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedOptionFirm(null);
    setSelectedOptionBill(null);
    setSelectedOptionOperator(null);
    setConsumerNumber("");
  };
  const handleShow = () => setShow(true);

  function handleCloseSuccess() {
    handleClose();
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }
  //   search select

  const [optionsFirm, setOptionsFirm] = useState([]);
  const [optionsOperator, setOptionsOperator] = useState([]);
  const [selectedOptionFirm, setSelectedOptionFirm] = useState(null);

  const [selectedOptionBill, setSelectedOptionBill] = useState(null);
  const [isGettingDetails, setIsGettingDetails] = useState(false);
  const [details, setDetails] = useState([]);
  const handleChangeBill = async (selectedOptionBill) => {
    setSelectedOptionBill(selectedOptionBill);
    if (selectedOptionBill?.value) {
      let operators = await getOprators(selectedOptionBill?.value);
      setOptionsOperator(operators);
    }
  };
  //   operator select

  const [selectedOptionOperator, setSelectedOptionOperator] = useState(null);

  const handleChangeOperator = (selectedOptionOperator) => {
    setSelectedOptionOperator(selectedOptionOperator);
    console.log("Selected Option:", selectedOptionOperator);
  };

  const [consumerNumber, setConsumerNumber] = useState("");
  const [amount, setAmount] = useState("");
  async function getRows() {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getutilitybill`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          site_code: selectedSite?.value || "",
          search_input: searchInput,
          service: selectedService?.value || "",
        },
      });

      setRows(response?.data?.data);

      // setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getcontractorsbyledgermaster`,
        method: "GET",
        params: {
          ledger_master: type,
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data;
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getOprators(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getoperator`,
        method: "GET",
        params: {
          service: type,
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data;
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getDetails() {
    setIsGettingDetails(true);
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getutilitybill_details`,
        method: "GET",
        params: {
          account: displayModal ? displayModal?.account : "",
          // account: "2000143965",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setDetails(response.data?.data);
      } else {
        setDetails([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingDetails(false);
    }
  }
  async function getServices() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getservice`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setServiceList(response.data?.data);
      } else {
        setServiceList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  async function handleAddBill(event) {
    event && event?.preventDefault();

    setIsBillAdding(true);
    try {
      const token = localStorage.getItem("him_user_token");

      const data = {
        service: selectedOptionBill?.value,
        spkey: selectedOptionOperator?.value,
        account: consumerNumber,
        site_code: selectedOptionFirm?.value,
        amount: amount,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/updateutilitybill`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 3000);
        getRows();
        handleClose();
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({
        title: "Error",
        err: error?.response?.data?.msg || error?.message,
      });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsBillAdding(false);
    }
  }

  async function handleToggle(row) {
    try {
      const token = localStorage.getItem("him_user_token");

      const data = {
        utility_id: row?.id,
        is_active: row?.is_active ? 0 : 1,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/updateutilitybill_status`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 3000);
        handleClose();
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({
        title: "Error",
        err: error?.response?.data?.msg || error?.message,
      });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      getRows();
    }
  }

  useEffect(() => {
    getRows();
  }, [selectedSite, selectedService]);

  useEffect(() => {
    if (!searchInput) {
      getRows();
    }
  }, [searchInput]);

  useEffect(() => {
    if (displayModal?.account) {
      getDetails();
    }
  }, [displayModal]);

  useEffect(() => {
    (async () => {
      // setSiteList((await getContractors("Office")) || []);
      setOptionsFirm(await getContractors("Firm,Employee,Director"));
    })();
    getServices();
  }, []);

  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">All Bill</h1>
        </div>
        <div className="d-flex mb-4">
          <div className="maxw-300 d-flex ">
            <input
              type="search"
              className="input-box "
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              disabled={!searchInput.length}
              onClick={() => {
                getRows();
              }}
              className="button button-brown ms-2"
            >
              <span>
                <svg className="icon">
                  <use href="#icon_search"></use>
                </svg>
              </span>
            </button>
          </div>
          <div className="ms-auto">
            {/* <button
              className="button button-brown px-4"
              type="button"
              onClick={() => setDisplayModal(true)}
            >
              Download sample
            </button>
            <button className="button button-brown px-4 ms-5" type="button">
              Bulk upload
            </button> */}
            <button
              className="button button-brown px-4 ms-5"
              type="button"
              onClick={handleShow}
            >
              + Add Bills
            </button>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose User
            </label>

            <Select
              value={selectedSite}
              onChange={setSelectedSite}
              options={optionsFirm.map((user) => ({
                value: user?.code,
                label: user?.code + "-" + user?.name,
              }))}
              isSearchable
              isClearable
            />
          </div>
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose Service
            </label>

            <Select
              value={selectedService}
              onChange={setSelectedService}
              options={serviceList.map((service) => ({
                value: service?.service,
                label: service?.service,
              }))}
              isSearchable
              isClearable
            />
          </div>
        </div>

        <div className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="srtd">Sr. No.</th>
                <th>Firm/Person</th>
                <th>Code</th>
                <th>Operator</th>
                <th>Consumer code</th>
                {/* <th>Past Bill Amount</th> */}
                <th>New Bill Amount</th>
                <th>Due Date</th>
                <th>Payment Status</th>
                <th>Active/Inactive</th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    {/* <td>{parseInt(page) * limit + index + 1}</td> */}
                    <td>{index + 1}</td>
                    <td>{row?.sitedata?.name}</td>
                    <td>{row?.site_code}</td>
                    <td>{row?.operator?.operator}</td>
                    <td
                    // style={{
                    //   cursor: "pointer",
                    // }}
                    >
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setDisplayModal(row);
                        }}
                      >
                        {row?.account}
                      </a>
                    </td>
                    {/* <td>{row?.last_bill_amount}</td> */}
                    <td>{row?.last_bill_amount}</td>
                    <td>
                      {row?.last_bill_due_date
                        ? moment(row?.last_bill_due_date).format("DD MMM YYYY")
                        : ""}
                    </td>
                    <td>
                      {/* 0=Not Paid, 1=partial, 2=Paid, 3=Failed */}
                      {/* {row?.payment_status == 1 ? (
                        <span className="badge rounded-2 text-bg-paid">
                          Paid
                        </span>
                      ) : (
                        <span className="badge rounded-2 text-bg-unpaid">
                          Unpaid
                        </span>
                      )} */}
                      {row?.payment_status == 0 && (
                        <span className="badge rounded-2 text-bg-unpaid">
                          Unpaid
                        </span>
                      )}
                      {row?.payment_status == 1 && (
                        <span className="badge rounded-2 text-bg-partial">
                          Partial
                        </span>
                      )}
                      {row?.payment_status == 2 && (
                        <span className="badge rounded-2 text-bg-paid">
                          Paid
                        </span>
                      )}
                      {row?.payment_status == 3 && (
                        <span className="badge rounded-2 text-bg-failed">
                          Failed
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleToggle(row)}
                    >
                      {row?.is_active ? (
                        <span className="badge rounded-2 text-bg-paid">
                          Active
                        </span>
                      ) : (
                        <span className="badge rounded-2 text-bg-unpaid">
                          Inactive
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>
      </div>
      {/* modal himcon */}
      <Modal
        show={displayModal}
        centered
        onHide={() => {
          setDisplayModal(false);
          setDetails([]);
        }}
        aria-labelledby="example-custom-modal-styling-title"
        className="modal-min-w90"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="text-center w-100 text-brown"
          >
            {displayModal?.sitedata?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-gray pt-0">
          <div className="d-flex text-center">
            <div className="mx-auto fs-22 fw-500">
              Consumer Code - {displayModal?.account}
            </div>
          </div>
          <div className="fs-22 fw-500">{displayModal?.operator?.operator}</div>
          <div className="tbl-scroller">
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th>Bill Number</th>
                  <th>Bill Amount</th>
                  <th>Bill Date</th>
                  <th>Due Date</th>
                  <th>Paid Date</th>
                  <th>Paid Amount</th>
                  <th>Transaction ID</th>
                </tr>
              </thead>
              <tbody className="tbody-gap">
                <tr>
                  <td colSpan="13"></td>
                </tr>
              </tbody>
              {!isGettingDetails && (
                <tbody>
                  {details.map((row, index) => (
                    <tr>
                      <td>{row?.bill_fetch_id}</td>
                      <td className="tbl-br-l">{row?.bill_amount}</td>
                      <td className="tbl-br-l">
                        {row?.bill_date
                          ? moment(row?.bill_date).format("DD MMM YYYY")
                          : ""}
                      </td>
                      <td className="tbl-br-l">
                        {row?.bill_due_date
                          ? moment(row?.bill_due_date).format("DD MMM YYYY")
                          : ""}
                      </td>
                      <td className="tbl-br-l">
                        {row?.bill_paid_date
                          ? moment(row?.bill_paid_date).format("DD MMM YYYY")
                          : ""}
                      </td>
                      <td className="tbl-br-l">{row?.paid_amount}</td>
                      <td className="tbl-br-l">{row?.trans_id}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {isGettingDetails && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader color={"#aa741f"} />
              </div>
            )}
            {!details.length && !isGettingDetails && (
              <div className="d-flex jc-center">
                <span>No data found</span>
              </div>
            )}
            {/* <tr>
                <td>BMK22334</td>
                <td className="tbl-br-l">Rs 1400</td>
                <td className="tbl-br-l">8-10-2024</td>
                <td className="tbl-br-l">15-10-2024</td>
                <td className="tbl-br-l">10-10-2024</td>
                <td className="tbl-br-l">ALT112255</td>
              </tr> */}
          </div>
        </Modal.Body>
      </Modal>
      {/* aside pop */}

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-brown">
            <span className="text-uderline">Add New Bill</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-gray">
          <form onSubmit={handleAddBill}>
            <div className="mb-4 select-box">
              <label htmlFor="person" className="mb-10 d-block">
                Firm/Person
              </label>
              <Select
                value={selectedOptionFirm}
                onChange={setSelectedOptionFirm}
                options={optionsFirm?.map((firm) => ({
                  value: firm?.code,
                  label: `${firm?.code}-${firm?.name}`,
                }))}
                placeholder="Select Firm/Person"
                isSearchable
                required
              />
            </div>

            <div className="mb-4 select-box">
              <label htmlFor="BillType" className="mb-10 d-block">
                Bill Type
              </label>
              <Select
                value={selectedOptionBill}
                onChange={handleChangeBill}
                options={serviceList.map((service) => ({
                  value: service?.service,
                  label: service?.service,
                }))}
                placeholder="Select Bill Type"
                isSearchable
                required
              />
            </div>

            <div className="mb-4 select-box">
              <label htmlFor="Operator" className="mb-10 d-block">
                Choose Operator
              </label>
              <Select
                value={selectedOptionOperator}
                onChange={handleChangeOperator}
                isDisabled={!selectedOptionBill?.value}
                options={optionsOperator?.map((operator) => ({
                  value: operator?.spkey,
                  label: operator?.operator,
                }))}
                placeholder="Select Operator"
                isSearchable
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="Operator" className="mb-10 d-block">
                Consumer Number
              </label>
              <input
                value={consumerNumber}
                onChange={(e) => setConsumerNumber(e.target.value)}
                type="text"
                className="input-box"
                placeholder="Enter number"
                required
              />
            </div>

            {(selectedOptionBill?.value == "Prepaid" ||
              selectedOptionBill?.value == "DTH" ||
              selectedOptionBill?.value == "Fastag") && (
              <div className="mb-4">
                <label htmlFor="Amount" className="mb-10 d-block">
                  Amount
                </label>
                <input
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  className="input-box"
                  placeholder="Enter number"
                  required
                />
              </div>
            )}

            <div className="mb-4 d-flex justify-content-end">
              <button
                onClick={handleClose}
                className="button button-default px-4 me-5"
                type="button"
              >
                Cancel
              </button>
              <button
                disabled={isBillAdding}
                className="button button-brown px-4"
                type="submit"
              >
                {isBillAdding ? <Loader /> : "Submit"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    </>
  );
}

export default AllBill;
