import React from "react";
import { Modal } from "react-bootstrap";

export function SuccessModal({
  showSuccess,
  handleCloseSuccess,
  successMessagePopup,
}) {
  return (
    <Modal
      show={showSuccess}
      centered
      onHide={handleCloseSuccess}
      className="tracking-modal"
    >
      <Modal.Body className="p-4 text-center">
        <div class="d-flex justify-content-center  text-brown pb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="55"
            height="55"
            fill="currentColor"
            className="bi bi-check-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <h3>Success</h3>
        <p>{successMessagePopup}</p>
      </Modal.Body>
    </Modal>
  );
}
