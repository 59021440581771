import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import "bootstrap/dist/css/bootstrap.min.css";

function CashTrackOffice() {
  const [rows, setRows] = useState([]);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [nestedRows, setNestedRows] = useState({});
  const [loadingNestedRows, setLoadingNestedRows] = useState({});
  const [openAccordion, setOpenAccordion] = useState(null);

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  const [selectedMonth, setSelectedMonth] = useState(moment().format("MM"));
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const navigate = useNavigate();

  async function getRows(month, year) {
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/getheadofficedailycash`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          month: month,
          year: year,
        },
      });

      return response?.data?.data || [];
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      return [];
    }
  }

  async function getNestedRows(toCode, fromDate, toDate) {
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/cashtracking`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          payment_type: 1,
          user_code: toCode,
          from_date: moment(`${selectedYear}-${selectedMonth}-01`).format(
            "YYYY-MM-DD"
          ),
          to_date: moment(
            `${selectedYear}-${selectedMonth}-${moment(
              `${selectedYear}-${selectedMonth}`,
              "YYYY-MM"
            ).daysInMonth()}`
          ).format("YYYY-MM-DD"),
        },
      });

      return response?.data?.data || [];
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      return [];
    }
  }

  useEffect(() => {
    (async () => {
      setIsGettingRows(true);
      // close all accordions
      setOpenAccordion(null);
      const initialRows = await getRows(selectedMonth, selectedYear);
      setRows(initialRows);
      setIsGettingRows(false);
    })();
  }, [selectedMonth, selectedYear]);

  const handleAccordionToggle = async (index, toCode) => {
    setOpenAccordion(index === openAccordion ? null : index);
    if (index !== openAccordion) {
      setLoadingNestedRows((prev) => ({
        ...prev,
        [index]: true,
      }));
      const nestedData = await getNestedRows(
        toCode
        // `${selectedYear}-${selectedMonth}-01`,
        // `${selectedYear}-${selectedMonth}-${moment(
        //   `${selectedYear}-${selectedMonth}`,
        //   "YYYY-MM"
        // ).daysInMonth()}`
      );
      setNestedRows((prev) => ({
        ...prev,
        [index]: nestedData,
      }));
      setLoadingNestedRows((prev) => ({
        ...prev,
        [index]: false,
      }));
    }
  };

  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Cash Track Head Office</h1>
        </div>

        <div className="mb-20 d-flex gray justify-content-between">
          <div className="d-flex">
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                Select Month
              </label>
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                className="input-box"
              >
                {moment.months().map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                Select Year
              </label>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                className="input-box"
              >
                {Array.from(
                  new Array(200),
                  (val, index) => index + moment().year() - 50
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="">
          {isGettingRows ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          ) : rows?.length ? (
            <Accordion activeKey={openAccordion}>
              {rows.map((row, index) => (
                <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header
                    className=""
                    onClick={() => handleAccordionToggle(index, row.to_code)}
                  >
                    <div className="d-flex accordion-header w-100">
                      <div className="bold">
                        {moment(`${row?.month}/1/${row?.year}`).format(
                          "MMM YYYY"
                        )}
                      </div>
                      {/* <div className="divider" /> */}

                      {/* <div className="">
                        <span className="fw-600">From: </span>
                        {row?.from_code}
                        {row?.fromdata?.name ? "-" : ""}
                        {row?.fromdata?.name}
                      </div> */}

                      <div className="divider" />

                      <div className="">
                        <span className="fw-600">To: </span>
                        {row?.to_code}
                        {row?.todata?.name ? "-" : ""}
                        {row?.todata?.name}
                      </div>
                      <div className="divider" />

                      <div className="">
                        <span className="fw-600">Received Amount: </span>
                        {row?.credit_amount}
                      </div>
                      <div className="divider" />
                      <div className="">
                        <span className="fw-600">Current Wallet Balance: </span>
                        {row?.todata?.wallet_bal}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {loadingNestedRows[index] ? (
                      <Loader color={"#aa741f"} />
                    ) : nestedRows[index]?.length ? (
                      <div className="child-accordion">
                        <Accordion>
                          {nestedRows[index].map((nestedRow, nestedIndex) => (
                            <Accordion.Item
                              key={nestedIndex}
                              eventKey={nestedIndex}
                            >
                              <Accordion.Header className="">
                                <div className="d-flex accordion-header w-100">
                                  <div className="bold">
                                    {moment(nestedRow?.date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </div>
                                  {/* <div className="divider" /> */}

                                  {/* <div className="">
                                  <span className="fw-600">From: </span>
                                  {nestedRow?.from_code}
                                  {nestedRow?.fromdata?.name ? "-" : ""}
                                  {nestedRow?.fromdata?.name}
                                </div> */}

                                  <div className="divider" />

                                  <div className="">
                                    <span className="fw-600">To: </span>
                                    {nestedRow?.to_code}
                                    {nestedRow?.todata?.name ? "-" : ""}
                                    {nestedRow?.todata?.name}
                                  </div>
                                  <div className="divider" />

                                  <div className="">
                                    <span className="fw-600">
                                      Paid Amount:{" "}
                                    </span>
                                    {nestedRow?.debit_amount}
                                  </div>
                                  <div className="divider" />

                                  {/* <div className="">
                                  <span className="fw-600">
                                    Received Amount:{" "}
                                  </span>
                                  {nestedRow?.credit_amount}
                                </div> */}
                                </div>
                              </Accordion.Header>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    ) : (
                      <div>No data found</div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>
      </div>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default CashTrackOffice;
