import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import AddArticleGstModal from "../components/AddArticleGstModal";

function ItemMaster() {
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [rows, setRows] = useState([]);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [isUpdatingItem, setIsUpdatingItem] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const handleCloseAddItem = () => setShowAddItem(false);
  const handleShowAddItem = (data) => setShowAddItem(data);

  const navigate = useNavigate();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  async function getRows() {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getitemslist`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      setRows(response?.data?.data);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function handleAddEditItem(
    event,
    article,
    gst_ratio,
    handleCloseCallback
  ) {
    event && event?.preventDefault();

    setIsUpdatingItem(true);
    try {
      const token = localStorage.getItem("him_user_token");

      const data = {
        // item_id: showAddItem?.item_id,
        // article,
        // gst_ratio,
        data: [
          {
            item_id: showAddItem?.item_id,
            item_name: article,
            gst_percent: gst_ratio,
          },
        ],
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/updateitems`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
        handleCloseCallback ? handleCloseCallback() : handleCloseAddItem();
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      getRows();
      setIsUpdatingItem(false);
    }
  }
  useEffect(() => {
    getRows();
  }, []);
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Item Master</h1>
          <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={() => handleShowAddItem(true)}
            >
              Add
            </button>
          </div>
        </div>

        <div className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="srtd"> Sr. No. </th>
                <th> Item </th>
                <th> GST Ratio </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    <td>{index + 1}</td>
                    <td>{row?.item_name}</td>
                    <td>{row?.gst_percent}</td>
                    <td>
                      <button
                        onClick={() => handleShowAddItem(row)}
                        className="button  px-2"
                        type="button"
                      >
                        <svg className="icon fs-22">
                          <use href="#icon_edit"></use>
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>
      </div>

      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <AddArticleGstModal
        showAddItem={showAddItem}
        isUpdatingItem={isUpdatingItem}
        handleAddEditItems={handleAddEditItem}
        handleCloseAddItem={handleCloseAddItem}
      />
    </>
  );
}

export default ItemMaster;
