import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";

function SiteConsumption() {
  const context = useOutletContext();
  const reports = context?.reports || [];
  const [items, setItems] = useState([]);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [selectedSite, setSelectedSite] = useState();
  const [siteList, setSiteList] = useState([]);
  const [editingRow, setEditingRow] = useState(null); // State to track the editing row
  const [isDataEdited, setIsDataEdited] = useState(false); // State to track if data is edited
  const navigate = useNavigate();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  async function getRows() {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/siteitemconsumption`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          site_code: selectedSite?.value || null,
        },
      });
      let itemsToSet = response?.data?.items || [];
      let dataRows = response?.data?.data || [];
      itemsToSet = itemsToSet.map((item) => {
        let dataRow = dataRows.find((row) => row.item_id === item.item_id);
        item.item_id = item.item_id;
        item.item_name = item.item_name;
        item.consumption_type = dataRow?.consumption_type || "1";
        item.daily_consumption = dataRow?.daily_consumption || 0;
        item.current_stock = dataRow?.current_stock || 0;
        return item;
      });
      setItems(response?.data?.items || []);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function getContractors() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getcontractorsbyledgermaster`,
        method: "GET",
        params: {
          ledger_master: "Office",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setSiteList(response.data?.data);
      } else {
        setSiteList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  async function handleSaveHomeExpenses() {
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/updatebulksiteitem`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          data: items.map((item) => ({
            item_id: item.item_id,
            item_name: item.item_name,
            consumption_type: item.consumption_type,
            daily_consumption: item.daily_consumption,
            current_stock: item.current_stock,
            site_code: selectedSite?.value,
          })),
        },
      });

      if (response.status === 200) {
        setshowSuccess(true);
        setSuccessMessagePopup(
          response?.data?.msg || "Data saved successfully"
        );
        setTimeout(() => {
          setshowSuccess(false);
          setSuccessMessagePopup("");
        }, 2000);
        setIsDataEdited(false); // Reset the edited state after saving
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    if (selectedSite?.value) {
      getRows();
    } else {
      setItems([]);
      setIsDataEdited(false); // Reset the edited state when the site is changed
    }
  }, [selectedSite]);

  useEffect(() => {
    getContractors();
  }, []);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (editingRow !== null) {
        setEditingRow(null);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [editingRow]);

  const handleRowClick = (e, rowIndex) => {
    e.stopPropagation(); // Prevent the document click handler from being triggered
    setEditingRow(rowIndex);
  };

  const handleInputChange = (e, rowIndex, columnName) => {
    const newItems = [...items];
    newItems[rowIndex][columnName] = e.target.value;
    setItems(newItems);
    setIsDataEdited(true); // Set the edited state to true
  };

  const handleSelectChange = (e, rowIndex, columnName) => {
    const newItems = [...items];
    newItems[rowIndex][columnName] = e.target.value;
    setItems(newItems);
    setIsDataEdited(true); // Set the edited state to true
  };

  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Site Consumption</h1>
        </div>

        <div className="mb-20 d-flex gray justify-content-between align-items-end">
          <div className="d-flex">
            <div className="w-45 me-4">
              <label htmlFor="" className="mb-10 d-block">
                Choose Site
              </label>

              <Select
                value={selectedSite}
                onChange={setSelectedSite}
                options={siteList.map((user) => ({
                  value: user?.code,
                  label: user?.code + "-" + user?.name,
                }))}
                isSearchable
                isClearable
              />
            </div>
          </div>
          {selectedSite?.value && (
            <div className="d-flex justify-content-between mt-4 ">
              <button
                onClick={() => {
                  setItems([
                    ...items,
                    {
                      item_name: "",
                      consumption_type: "1",
                      daily_consumption: 0,
                      current_stock: 0,
                    },
                  ]);
                  setIsDataEdited(true); // Set the edited state to true when a new row is added
                }}
                type="button"
                className="button button-brown"
              >
                + Add Row
              </button>

              <button
                onClick={handleSaveHomeExpenses}
                type="button"
                className="button button-brown ms-4"
                disabled={!isDataEdited} // Disable the button if no data is edited
              >
                Save
              </button>
            </div>
          )}
        </div>
        <hr className="mr-30 my-10"></hr>
        <div className="tbl-scroller p-1 ">
          {!isGettingRows && items?.length ? (
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="srtd">Sr.No.</th>
                  <th>Item Name</th>
                  <th>Consumption/Day</th>
                  <th>Daily Consumption</th>
                  <th>Current Stock</th>
                </tr>
              </thead>
              <tbody className="tbody-gap">
                <tr>
                  <td colSpan="13"></td>
                </tr>
              </tbody>

              <tbody>
                {items.map((row, rowIndex) => (
                  <tr
                    key={row?.item_id}
                    onClick={(e) => handleRowClick(e, rowIndex)}
                  >
                    <td>{rowIndex + 1}</td>
                    <td>
                      {editingRow === rowIndex && !row?.item_id ? (
                        <input
                          className="input-box"
                          type="text"
                          value={row.item_name}
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "item_name")
                          }
                        />
                      ) : (
                        row.item_name
                      )}
                    </td>
                    <td>
                      {editingRow === rowIndex ? (
                        <select
                          className="input-box"
                          value={row.consumption_type}
                          onChange={(e) =>
                            handleSelectChange(e, rowIndex, "consumption_type")
                          }
                        >
                          <option value="0">Not Applicable</option>
                          <option value="1">Percentage</option>
                          <option value="2">Quantity</option>
                        </select>
                      ) : (
                        (() => {
                          switch (row?.consumption_type) {
                            case 1:
                              return "Percentage";
                            case 2:
                              return "Quantity";
                            default:
                              return "Not Applicable";
                          }
                        })()
                      )}
                    </td>
                    <td>
                      {editingRow === rowIndex ? (
                        <input
                          className="input-box"
                          type="number"
                          value={row.daily_consumption}
                          min={0}
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "daily_consumption")
                          }
                        />
                      ) : (
                        row.daily_consumption
                      )}
                    </td>
                    <td>
                      {editingRow === rowIndex ? (
                        <input
                          className="input-box"
                          type="number"
                          value={row.current_stock}
                          min={0}
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "current_stock")
                          }
                        />
                      ) : (
                        row.current_stock
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
        </div>
      </div>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default SiteConsumption;
