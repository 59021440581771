import React from "react";
import { Button, Modal } from "react-bootstrap";

function ErrorModal({ showErrModal, setShowErrModal, error }) {
  /* error show modal */
  return (
    <Modal show={showErrModal} onHide={() => setShowErrModal(false)} centered>
      <Modal.Body className="p-4">
        <p className="fs-4 text-center text-danger">
          <b>{error?.title}</b>
        </p>
        <p className="fs-4 text-center text-danger">{error?.err}</p>
        <div className="d-flex justify-content-center mt-2">
          <Button
            variant="secondary"
            className="me-3"
            onClick={() => setShowErrModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ErrorModal;
