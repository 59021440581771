import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import Loader from "./Loader";

function InvoiceViewModal({ showInvoice, handleClose }) {
  return (
    <Modal
      size="xl"
      show={showInvoice}
      onHide={handleClose}
      animation={true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Invoice : {showInvoice?.inv_no}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          style={{ width: "100%" }}
          src={`${process.env.REACT_APP_BASE_URL}${showInvoice?.inv_file}`}
        />
      </Modal.Body>
    </Modal>
  );
}

export default InvoiceViewModal;
