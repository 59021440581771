import React from "react";

function Loader({ color }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          border: `0.25rem solid ${color || "#ffffff"}`,
          borderRadius: "50%",
          width: "2rem",
          height: "2rem",
          borderTopColor: "transparent",
          animation: "spin 0.75s linear infinite",
        }}
        role="status"
        title="loading..."
      >
        <span
          style={{
            position: "absolute",
            width: "1px",
            height: "1px",
            padding: "0",
            margin: "-1px",
            overflow: "hidden",
            clip: "rect(0,0,0,0)",
            border: "0",
          }}
        >
          Loading...
        </span>
      </div>
    </div>
  );
}

export default Loader;
